<template lang="pug">
div.container
  div(v-if="activeAffiliates.length == 0 && inactiveAffiliates.length == 0")
    loading-spinner(message="Loading affiliates...")
  div(v-if="activeAffiliates.length > 0 || inactiveAffiliates.length > 0")
    b-row(align-h="between" align-v="center")
      h1 Manage Affiliates <router-link to="/affiliates/new"><b-button variant="success"><fa-icon icon="plus" size="lg"></fa-icon></b-button></router-link>
      b-form(inline)
        b-input(type="text" placeholder="search" v-model="searchText" @input="resultLimit = 20")

    div.jumbotron.justify-content-center.text-center
      h3 We have {{totalMembers}} active members in {{activeAffiliates.length}} affiliates

    div.row
      h2 Active Affiliates
      b-table(hover striped head-variant="dark" tbody-tr-class="table-row-link" @row-clicked="(item, index) => { $router.push('/affiliates/' + item._id) }" :fields="tableFields" :items="searchActiveAffiliatesResults" :sort-by.sync="sortActiveBy" :sort-desc.sync="sortActiveDesc")
        template(v-slot:cell(icon)="data")
          fa-icon(v-if="data.item.status == 'valid'" icon="check")/
          fa-icon(v-else-if="data.item.status == 'expiring'" icon="clock")/
        template(v-slot:cell(organisationType)="data"): fa-icon(:icon="data.value === 'Business' ? 'city' : 'graduation-cap'")/
        template(v-slot:cell(name)="data") {{data.item.name}} {{data.item.createdDate.getFullYear()}}
        template(v-slot:cell(members)="data") {{data.value}}
        template(v-slot:cell(validUntil)="data") {{data.item.validUntil | moment('MMMM YYYY')}}

    div.row.justify-content-center.text-center
      p {{searchActiveAffiliatesResults.length}} search results

    div.row
      h2 Expired Affiliates
      b-table(hover striped head-variant="dark" tbody-tr-class="table-row-link" @row-clicked="(item, index) => { $router.push('/affiliates/' + item._id) }" :fields="tableFields" :items="displayedInactiveAffiliates" :sort-by.sync="sortExpiredBy" :sort-desc.sync="sortExpiredDesc")
        template(v-slot:cell(icon)="data"): fa-icon(icon="times")/
        template(v-slot:cell(organisationType)="data"): fa-icon(:icon="data.value === 'Business' ? 'city' : 'graduation-cap'")/
        template(v-slot:cell(name)="data") {{data.item.name}} {{data.item.createdDate.getFullYear()}}
        template(v-slot:cell(members)="data") {{data.value}}
        template(v-slot:cell(validUntil)="data") {{data.item.validUntil | moment('MMMM YYYY')}}

    div.row.justify-content-center.text-center
      p {{searchInactiveAffiliatesResults.length}} search results
        span(v-show="displayedInactiveAffiliates.length < searchInactiveAffiliatesResults.length")  ({{displayedInactiveAffiliates.length}} shown)
    div.row.justify-content-center
      b-button(v-show="displayedInactiveAffiliates.length < searchInactiveAffiliatesResults.length" size="sm" variant="info" @click="loadMore()") Load more

    div.row.justify-content-center.mt-4
      b-button(variant="info" href="/api/affiliates/csv/") Download All Affiliate Members as CSV
      b-button.ml-2(variant="info" href="/api/affiliates/valid/csv/") Download Valid Affiliate Members as CSV
</template>

<script>
export default {
  data: function () {
    return {
      resultLimit: 20,
      activeAffiliates: [],
      inactiveAffiliates: [],
      searchText: '',
      totalMembers: 0,
      now: new Date(),
      expiryThreshold: new Date(),
      tableFields: [
        { key: 'icon', label: '', sortable: false },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'organisationType', label: 'Type', sortable: true },
        { key: 'members',
          label: 'Membership Count',
          sortable: true,
          formatter: (value, key, item) => {
            return item.members.length
          }
        },
        { key: 'validUntil', label: 'Expiry Date', sortable: true }
      ],
      sortActiveBy: 'validUntil',
      sortActiveDesc: false,
      sortExpiredBy: 'validUntil',
      sortExpiredDesc: true
    }
  },
  computed: {
    searchActiveAffiliatesResults: function () {
      return this.activeAffiliates.filter(this.searchFunction)
    },
    searchInactiveAffiliatesResults: function () {
      return this.inactiveAffiliates.filter(this.searchFunction)
    },
    displayedInactiveAffiliates: function () {
      return this.searchInactiveAffiliatesResults.sort((a,b) => b.validUntil - a.validUntil).slice(0, this.resultLimit)
    }
  },
  methods: {
    searchFunction: function (affiliate) {
      let s = this.searchText.toLowerCase()
      return affiliate.name.toLowerCase().includes(s)
    },
    loadMore: function () {
      this.resultLimit += 20
    },
    sortByName: function (a, b) {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    }
  },
  mounted: function () {
    this.expiryThreshold.setFullYear(this.now.getFullYear(), this.now.getMonth() + 2, 0)

    this.axios.get('/api/affiliates')
      .then(res => {
        // res.data.sort(this.sortByName)
        let actives = []; let inactives = []
        for (let affiliate of res.data) {
          affiliate.validUntil = new Date(affiliate.validUntil)
          affiliate.createdDate = new Date(affiliate.createdDate)

          // For all active affiliates...
          if (affiliate.validUntil > this.now) {
            this.totalMembers += affiliate.members.length

            // Compute affiliateship status
            if (affiliate.validUntil <= this.expiryThreshold && affiliate.affiliateshipType !== 'Lifetime') {
              affiliate.status = 'expiring'
            } else {
              affiliate.status = 'valid'
            }

            actives.push(affiliate)
          } else {
            affiliate.status = 'expired'
            inactives.push(affiliate)
          }
        }

        // Push to DOM
        this.activeAffiliates = actives
        this.inactiveAffiliates = inactives
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching affiliates failed',
          text: err.hasOwnProperty('response') ? err.response.data : err,
          duration: -1
        })
      })
  }
}
</script>
